import React from "react";
import ReactDOM from "react-dom/client";
import ContentNormal from "./ContentNormal";
import ContentProgram from "./ContentProgram";
import { NavItemModalActive, NavItemModal } from "./NavItem.js";

class content extends React.Component {
  render() {
    return (
      <>
        <div className="tab-content">
          <div className="tab-pane active" id="nomal">
            <ul className="nav nav-tabs pb-3">
              <NavItemModalActive
                target={"#splitString"}
                children={"文字分割"}
                modalTarget={"#splitStringHelper"}
              />
              <NavItemModal
                target={"#joinString"}
                children={"文字結合"}
                modalTarget={"#joinStringHelper"}
              />
              <NavItemModal
                target={"#specialSplitString"}
                children={"特殊文字分割"}
                modalTarget={"#specialStringHelper"}
              />
              <NavItemModal
                target={"#beforeAppendString"}
                children={"文字追加(前)"}
                modalTarget={"#beforeAppendStringHelper"}
              />
              <NavItemModal
                target={"#afterAppendString"}
                children={"文字追加(後)"}
                modalTarget={"#afterAppendStringHelper"}
              />
            </ul>
            <ContentNormal />
          </div>
          <div className="tab-pane pb-3" id="program">
            <ul className="nav nav-tabs">
              <NavItemModalActive
                target={"#JoinCamelCase"}
                children={"キャメルケース(結合)"}
                modalTarget={"#joinCamelcaseHelper"}
              />
              <NavItemModal
                target={"#splitCamelcase"}
                children={"キャメルケース(分割)"}
                modalTarget={"#splitCamelcaseHelper"}
              />
              <NavItemModal
                target={"#joinSnakecase"}
                children={"スネークケース(結合)"}
                modalTarget={"#joinSnakecaseHelper"}
              />

              <NavItemModal
                target={"#splitSnakecase"}
                children={"スネークケース(分割)"}
                modalTarget={"#splitSnakecaseHelper"}
              />
            </ul>
            <ContentProgram />
          </div>
        </div>
      </>
    );
  }
}

export default content;
