import React from "react";

import ReactDOM from "react-dom/client";
import JoinCamelCase from "./JoinCamelCase";
import SplitCamelCase from "./SplitCamelCase";
import JoinSnakeCase from "./JoinSnakeCase";
import SplitSnakeCase from "./SplitSnakeCase";

class ContentProgram extends React.Component {
  render() {
    return (
      <div className="tab-content">
        <JoinCamelCase />
        <SplitCamelCase />
        <JoinSnakeCase />
        <SplitSnakeCase />
      </div>
    );
  }
}

export default ContentProgram;
