import React from "react";
import {
  isLength,
  inString,
  checkvalid,
  copyClick,
} from "../commonfunction/function.js";
import { ExeButton, CopyButton } from "./elements/Button.js";
import { InvalidFeedback } from "./elements/InvalidFeedback.js";
import { Tooltip } from "./ModalTooltip.js";
import { OutputTextArea, InputTextBox } from "./elements/Input.js";

class SplitCamelCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: "",
      appendString: "",
      outptString: "",
      collposeClass: "form-group collapse",
      collposeClassBtn: "btn btn-secondary collapse",
    };

    this.stringshandleChange = this.stringshandleChange.bind(this);
    this.myRef = React.createRef();
    this.radio1Ref = React.createRef();
    this.radio2Ref = React.createRef();
    this.radio1handleChange = this.radio1handleChange.bind(this);
    this.radio2handleChange = this.radio2handleChange.bind(this);
    this.radioarray = [
      { ref: this.radio1Ref, handleChange: this.radio1handleChange },
      { ref: this.radio2Ref, handleChange: this.radio2handleChange },
    ];
  }
  handleClick = (t) => {
    if (checkvalid(t.myRef.current)) {
      t.setState({
        collposeClass: "form-group collapse show",
      });
      t.setState({
        collposeClassBtn: "btn btn-secondary collapse show",
      });

      t.setState({
        outptString: t.state.strings.replaceAll("_", "\n"),
      });
    }
    t.state.collposeClass = "form-group collapse show";
  };
  stringshandleChange(event) {
    //console.log(event.target.value);
    this.setState({ strings: event.target.value });
    //console.log(this.state.splitString);
  }
  radio1handleChange(event) {
    if (event.target.checked) {
      this.radio2Ref.current.checked = false;
    }
  }
  radio2handleChange(event) {
    if (event.target.checked) {
      this.radio1Ref.current.checked = false;
    }
  }
  render() {
    return (
      <>
        {/* <!-- 文字追加表示(前) --> */}
        <div className="tab-pane" id="splitSnakecase">
          <form className="needs-validation" novalidate ref={this.myRef}>
            <div className="form-group">
              <label>
                結合用文字列
                <Tooltip title="スネークケース作成用の文字列を記載してください" />
                <InputTextBox
                  placeholder="対象文字列"
                  value={this.state.strings}
                  onChange={this.stringshandleChange}
                />
                <InvalidFeedback />
              </label>
            </div>
            <div className={this.state.collposeClass}>
              <label>
                結果
                <OutputTextArea value={this.state.outptString} />
              </label>
            </div>
            <ExeButton
              onClick={() => {
                this.handleClick(this);
              }}
            />
            <CopyButton
              className={this.state.collposeClassBtn}
              onClick={() => {
                copyClick(this.myRef.current, this.state.outptString);
              }}
            />
          </form>
        </div>

        {/* <!-- 文字結合表示END --> */}
      </>
    );
  }
}
export default SplitCamelCase;
