import { Tooltip, Modal } from "./ModalTooltip.js";

export const NavItemTooltipActive = ({ target, children, title }) => {
  return (
    <>
      <NavItemTooltipBase
        children={children}
        className={"link-light nav-link d-inline pe-1 active"}
        target={target}
        title={title}
      />
    </>
  );
};
export const NavItemTooltip = ({ target, children, title }) => {
  return (
    <>
      <NavItemTooltipBase
        children={children}
        className={"link-light nav-link d-inline pe-1"}
        target={target}
        title={title}
      />
    </>
  );
};

const NavItemTooltipBase = (props) => {
  return (
    <>
      <li className="nav-item">
        <a
          className={`${props.className}`}
          aria-current="page"
          data-bs-toggle="tab"
          data-bs-target={`${props.target}`}
          href="#"
        >
          {`${props.children}`}
        </a>
        <Tooltip title={`${props.title}`} />
      </li>
    </>
  );
};

export const NavItemModalActive = ({ target, children, modalTarget }) => {
  return (
    <>
      <NavItemModalBase
        children={children}
        className={"link-light nav-link d-inline pe-1 active"}
        target={target}
        modalTarget={modalTarget}
      />
    </>
  );
};

export const NavItemModal = ({ target, children, modalTarget }) => {
  return (
    <>
      <NavItemModalBase
        children={children}
        className={"link-light nav-link d-inline pe-0"}
        target={target}
        modalTarget={modalTarget}
      />
    </>
  );
};

const NavItemModalBase = (props) => {
  return (
    <>
      <li className="nav-item">
        <a
          className={`${props.className}`}
          aria-current="page"
          data-bs-toggle="tab"
          data-bs-target={`${props.target}`}
          href="#"
        >
          {`${props.children}`}
        </a>
        <Modal modalTarget={`${props.modalTarget}`} />
      </li>
    </>
  );
};
