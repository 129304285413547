/**
 *　入力エリア
 * @param {String} placeholder:placeholder表示用文字列
 * @param {String} value:入力情報文字列
 * @param {function} onChange:変更時の関数
 */
export const InputTextBox = ({ placeholder, value, onChange }) => {
  return (
    <input
      type="text"
      className="form-control"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required
    />
  );
};

/**
 *　出力エリア
 * @param {String} value:入力情報文字列
 */
export const OutputTextBox = ({ value }) => {
  return <input type="text" className="form-control" value={value} />;
};

/**
 *入力テキストエリア
 * @param {String} placeholder:placeholder表示用文字列
 * @param {String} value:入力情報文字列
 * @param {function} onChange:変更時の関数
 */
export const InputTextArea = ({ placeholder, value, onChange }) => {
  return (
    <textarea
      className="form-control"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      rows="10"
    ></textarea>
  );
};

/**
 * 文字列出力用テキストエリア
 * @param {String} value:入力情報文字列
 */
export const OutputTextArea = ({ value }) => {
  return <textarea className="form-control" value={value} rows="10"></textarea>;
};
