import React from "react";
import {
  isLength,
  inString,
  checkvalid,
  copyClick,
} from "../commonfunction/function.js";
import { ExeButton, CopyButton } from "./elements/Button.js";
import { InvalidFeedback } from "./elements/InvalidFeedback.js";
import { Tooltip } from "./ModalTooltip.js";
import { InputTextArea, OutputTextBox } from "./elements/Input.js";

class JoinSnakeCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: "",
      appendString: "",
      outptString: "",
      collposeClass: "form-group collapse",
      collposeClassBtn: "btn btn-secondary collapse",
    };

    this.stringshandleChange = this.stringshandleChange.bind(this);
    this.myRef = React.createRef();
  }
  handleClick = (t) => {
    if (checkvalid(t.myRef.current)) {
      t.setState({
        collposeClass: "form-group collapse show",
      });
      t.setState({
        collposeClassBtn: "btn btn-secondary collapse show",
      });

      let array = t.state.strings.split("\n");
      let ret = "";
      let i = 0;
      for (let obj in array) {
        if (i++ == 0) {
          ret += array[obj].toLowerCase();
        } else {
          ret += "_" + array[obj].toLowerCase();
        }
      }

      t.setState({
        outptString: ret,
      });
    }
    t.state.collposeClass = "form-group collapse show";
  };
  stringshandleChange(event) {
    //console.log(event.target.value);
    this.setState({ strings: event.target.value });
    //console.log(this.state.splitString);
  }

  render() {
    return (
      <>
        <div className="tab-pane" id="joinSnakecase">
          <form className="needs-validation" novalidate ref={this.myRef}>
            <div className="form-group">
              <label>
                結合用文字列
                <Tooltip title="キャメルケース作成用の文字列を記載してください" />
                <InputTextArea
                  placeholder="対象文字列"
                  value={this.state.strings}
                  onChange={this.stringshandleChange}
                />
                <InvalidFeedback />
              </label>
            </div>
            <div className={this.state.collposeClass}>
              <label>
                結果
                <OutputTextBox value={this.state.outptString} />
              </label>
            </div>
            <ExeButton
              onClick={() => {
                this.handleClick(this);
              }}
            />
            <CopyButton
              className={this.state.collposeClassBtn}
              onClick={() => {
                copyClick(this.myRef.current, this.state.outptString);
              }}
            />
          </form>
        </div>
      </>
    );
  }
}
export default JoinSnakeCase;
