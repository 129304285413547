import Head from "./components/Header";
import Menu from "./components/Menu";
import { CopiedModal } from "./components/elements/CopiedModal.js";
import { BaseModal } from "./components/elements/Modal.js";

function App() {
  return (
    <>
      <Head />
      <Menu />
      <CopiedModal />
      <BaseModal
        id="splitStringHelper"
        title="文字列分割手順"
        body={[
          "1.分割したい文字を対象文字列欄に入力する。",
          "2.区切り文字をに区切り文字欄に入力する。",
          "3.実行ボタンを押します。",
        ]}
      />
      <BaseModal
        id="joinStringHelper"
        title="文字列結合手順"
        body={[
          "1.結合したい文字を対象文字列欄に改行区切りで入力する。",
          "2.結合文字を結合文字欄に入力する。",
          "3.実行ボタンを押す。",
        ]}
      />
      <BaseModal
        id="specialStringHelper"
        title="特殊文字列分割手順"
        body={[
          "1.分割したい文字を対象文字列欄に入力する。",
          "2.区切り文字をに区切り文字欄に入力する。",
          "3.実行ボタンを押す。",
        ]}
      />
      <BaseModal
        id="beforeAppendStringHelper"
        title="文字追加(前)手順"
        body={[
          "1.前に文字を入れたい文字列軍を結合用文字列欄に入力する。",
          "2.前に追加したい文字をに追加文字欄に入力する。",
          "3.実行ボタンを押します。",
        ]}
      />
      <BaseModal
        id="afterAppendStringHelper"
        title="文字追加(後)手順"
        body={[
          "1.後ろに文字を入れたい文字列軍を結合用文字列欄に入力する。",
          "2.後ろに追加したい文字をに追加文字欄に入力する。",
          "3.実行ボタンを押します。",
        ]}
      />
      <BaseModal
        id="joinCamelcaseHelper"
        title="キャメルケース(結合)手順"
        body={[
          "1.最初の文字を小文字/大文字指定するためにラジオボタンを選択する。",
          "2.対象文字を対象文字列欄に入力する。",
          "3.実行ボタンを押す。",
        ]}
      />
      <BaseModal
        id="splitCamelcaseHelper"
        title="キャメルケース(分割)手順"
        body={[
          "1.キャメルケース形式の文字列を記載する。",
          "2.実行ボタンを押す。",
        ]}
      />
      <BaseModal
        id="joinSnakecaseHelper"
        title="スネークケース(結合)手順"
        body={[
          "1.対象文字を結合用文字列欄に入力する。",
          "2.実行ボタンを押す。",
        ]}
      />
      <BaseModal
        id="splitSnakecaseHelper"
        title="スネークケース(分割)"
        body={[
          "1.スネークケース形式の文字列を記載する",
          "2.実行ボタンを押す。",
        ]}
      />
    </>
  );
}

export default App;
