import React from "react";

import ReactDOM from "react-dom/client";
import SplitString from "./SplitString";
import JoinString from "./JoinString";
import SpecialSplitString from "./SpecialSplitString";
import BeforeAppendtring from "./BeforeAppendtring";
import AfterAppendtring from "./AfterAppendtring";

class contentNormal extends React.Component {
  render() {
    return (
      <div className="tab-content">
        <SplitString />
        <JoinString />
        <SpecialSplitString />
        <BeforeAppendtring />
        <AfterAppendtring />
      </div>
    );
  }
}

export default contentNormal;
