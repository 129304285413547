/**
 * 実行ボタンを生成します。
 * @param {function} onClick:クリック時の関数
 */
export const ExeButton = ({ onClick }) => {
  return (
    <>
      <BaseButton
        name={"実行"}
        className={"btn btn-primary me-2"}
        onClick={onClick}
      />
    </>
  );
};

/**
 * 文字コピー用のボタンを生成します。
 * @param {String} className:class名の文字列
 * @param {function} onClick:クリック時の関数
 */
export const CopyButton = ({ className, onClick }) => {
  return (
    <>
      <BaseButton name={"コピー"} className={className} onClick={onClick} />
    </>
  );
};

/**
 * 文字コピー用のボタンを生成します。
 * @param {String} name:表示名称の文字列
 * @param {String} className:class名の文字列
 * @param {function} onClick:クリック時の関数
 */
export const CopyNameButton = ({ name, className, onClick }) => {
  return (
    <>
      <BaseButton name={name} className={className} onClick={onClick} />
    </>
  );
};

/**
 * ボタンのインターフェーフ
 * @param {String} name:表示名称の文字列
 * @param {String} className:class名の文字列
 * @param {function} onClick:クリック時の関数
 */
const BaseButton = ({ name, className, onClick }) => {
  return (
    <>
      <button type="button" className={className} onClick={onClick}>
        {name}
      </button>
    </>
  );
};
