import React from "react";
import {
  isLength,
  inString,
  checkvalid,
  copyClick,
} from "../commonfunction/function.js";
import { ExeButton, CopyButton } from "./elements/Button.js";
import { InvalidFeedback } from "./elements/InvalidFeedback.js";
import { Tooltip } from "./ModalTooltip.js";
import {
  InputTextBox,
  InputTextArea,
  OutputTextBox,
} from "./elements/Input.js";

class JoinString extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      joinString: "",
      joinkey: "",
      outptString: "",
      collposeClass: "form-group collapse",
      collposeClassBtn: "btn btn-secondary collapse",
    };
    this.joinStringhandleChange = this.joinStringhandleChange.bind(this);
    this.joinkeyhandleChange = this.joinkeyhandleChange.bind(this);
    this.myRef = React.createRef();
  }
  handleClick = (t) => {
    console.log(isLength(t.state.joinString, t.state.joinkey));
    if (checkvalid(t.myRef.current)) {
      t.setState({
        collposeClass: "form-group collapse show",
      });
      t.setState({
        collposeClassBtn: "btn btn-secondary collapse show",
      });

      t.setState({
        outptString: t.state.joinString.replaceAll("\n", this.state.joinkey),
      });
    }
    t.state.collposeClass = "form-group collapse show";
  };
  joinStringhandleChange(event) {
    //console.log(event.target.value);
    this.setState({ joinString: event.target.value });
    //console.log(this.state.splitString);
  }
  joinkeyhandleChange(event) {
    //console.log(event.target.value);
    this.setState({ joinkey: event.target.value });
    //console.log(this.state.splitkey);
  }
  render() {
    return (
      <>
        {/* <!-- 文字結合表示 --> */}
        <div className="tab-pane" id="joinString">
          <form className="needs-validation" novalidate ref={this.myRef}>
            <div className="form-group">
              <label>
                結合用文字列
                <Tooltip title="結合する文字列を複数記載してください" />
                <InputTextArea
                  placeholder="対象文字列"
                  value={this.state.joinString}
                  onChange={this.joinStringhandleChange}
                />
                <InvalidFeedback />
              </label>
            </div>
            <div className="form-group">
              <label>
                結合文字
                <Tooltip title="結合する文字列を記載してください" />
                <InputTextBox
                  placeholder="結合文字"
                  value={this.state.joinkey}
                  onChange={this.joinkeyhandleChange}
                />
                <InvalidFeedback />
              </label>
            </div>
            <div className={this.state.collposeClass}>
              <label>
                結果
                <OutputTextBox value={this.state.outptString} />
              </label>
            </div>
            <ExeButton
              onClick={() => {
                this.handleClick(this);
              }}
            />
            <CopyButton
              className={this.state.collposeClassBtn}
              onClick={() => {
                copyClick(this.myRef.current, this.state.outptString);
              }}
            />
          </form>
        </div>

        {/* <!-- 文字結合表示END --> */}
      </>
    );
  }
}

export default JoinString;
