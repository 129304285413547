import React from "react";
import {
  isLength,
  inString,
  checkvalid,
  copyClick,
} from "../commonfunction/function.js";
import { ExeButton, CopyNameButton } from "./elements/Button.js";
import { InvalidFeedback } from "./elements/InvalidFeedback.js";
import { Tooltip } from "./ModalTooltip.js";
import {
  InputTextBox,
  InputTextArea,
  OutputTextArea,
} from "./elements/Input.js";

class SpecialSplitString extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      splitString: "",
      splitkey: "",
      bfoutptString: "",
      afoutptString: "",
      collposeclass: "row collapse",
      collposeclassBtn: "btn btn-secondary collapse me-1",
    };
    this.splitStringhandleChange = this.splitStringhandleChange.bind(this);
    this.splitkeyhandleChange = this.splitkeyhandleChange.bind(this);
    this.myRef = React.createRef();
  }
  handleClick = (t) => {
    if (checkvalid(t.myRef.current)) {
      t.setState({
        collposeclassName: "row collapse show",
      });
      t.setState({
        collposeclassBtn: "btn btn-secondary collapse show me-1",
      });
      let array = t.state.splitString.split("\n");
      let bfArray = [];
      let afArray = [];
      for (let obj in array) {
        bfArray.push(
          array[obj].slice(0, array[obj].indexOf(this.state.splitkey))
        );
        afArray.push(
          array[obj].slice(
            array[obj].indexOf(this.state.splitkey) + 1,
            array[obj].length
          )
        );
      }
      t.setState({
        bfoutptString: bfArray.join("\n"),
      });
      t.setState({
        afoutptString: afArray.join("\n"),
      });
      t.state.collposeclass = "row collapse show";
    }
  };
  splitStringhandleChange(event) {
    //console.log(event.target.value);
    this.setState({ splitString: event.target.value });
    //console.log(this.state.splitString);
  }
  splitkeyhandleChange(event) {
    //console.log(event.target.value);
    this.setState({ splitkey: event.target.value });
    //console.log(this.state.splitkey);
  }
  render() {
    return (
      <>
        <div className="tab-pane" id="specialSplitString">
          <form className="needs-validation" novalidate ref={this.myRef}>
            <div className="form-group">
              <label>
                対象文字列
                <Tooltip title="分割する文字列を記載してください" />
                <InputTextArea
                  value={this.state.splitString}
                  onChange={this.splitStringhandleChange}
                  placeholder="対象文字列"
                />
                <InvalidFeedback />
              </label>
            </div>
            <div className="row">
              <div className="col-sm">
                <div className="form-group">
                  <label>
                    区切り文字
                    <Tooltip title="分割文字列を記載してください" />
                    <InputTextBox
                      placeholder="区切り文字"
                      value={this.state.splitkey}
                      onChange={this.splitkeyhandleChange}
                    />
                    <InvalidFeedback />
                  </label>
                </div>
              </div>
            </div>
            <div className={this.state.collposeclass}>
              <div className="col-6">
                <div className="form-group">
                  <label>
                    前文字列
                    <OutputTextArea value={this.state.bfoutptString} />
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>
                    後文字列
                    <OutputTextArea value={this.state.afoutptString} />
                  </label>
                </div>
              </div>
            </div>
            <ExeButton
              onClick={() => {
                this.state = this.handleClick(this);
              }}
            />
            <CopyNameButton
              name={"前コピー"}
              className={this.state.collposeclassBtn}
              onClick={() => {
                copyClick(this.myRef.current, this.state.bfoutptString);
              }}
            />
            <CopyNameButton
              name={"後コピー"}
              className={this.state.collposeclassBtn}
              onClick={() => {
                copyClick(this.myRef.current, this.state.afoutptString);
              }}
            />
          </form>
        </div>
      </>
    );
  }
}

export default SpecialSplitString;
