/**
 * コピー時表示するモーダルを生成します。
 */
export const CopiedModal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="copied"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"></h5>
            </div>
            <div clasNames="modal-body">コピーしました。</div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </>
  );
};
