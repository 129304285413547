import React from "react";
/**
 * 文字コピー用のボタンを生成します。
 * @param {String} name:表示名称の文字列
 * @param {String} className:class名の文字列
 * @param {function} onClick:クリック時の関数
 */
export const CamelCaseRadioCheck = ({ radioarray }) => {
  let array = [
    { name: "小文字", id: "cameSmall", disp: "小文字", obj: radioarray[0] },
    { name: "大文字", id: "cameBBig", disp: "大文字", obj: radioarray[1] },
  ];
  return (
    <>
      <BaseRadioCheck array={array} />
    </>
  );
};

/**
 * ボタンのインターフェーフ
 * @param {Array} array:表示名称の文字列
 */
const BaseRadioCheck = ({ array }) => {
  return (
    <>
      {array.map((line, index) => (
        <React.Fragment key={index}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name={line.name}
              id={line.id + String(index)}
              onChange={line.obj.handleChange}
              ref={line.obj.ref}
            />
            <label className="form-check-label" for={line.id + String(index)}>
              {line.disp}
            </label>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};
