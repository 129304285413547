import React from "react";
import {
  isLength,
  inString,
  checkvalid,
  copyClick,
} from "../commonfunction/function.js";
import { ExeButton, CopyButton } from "./elements/Button.js";
import { InvalidFeedback } from "./elements/InvalidFeedback.js";
import { Tooltip } from "./ModalTooltip.js";
import {
  InputTextBox,
  InputTextArea,
  OutputTextArea,
} from "./elements/Input.js";

class BeforeAppendtring extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: "",
      appendString: "",
      outptString: "",
      collposeClass: "form-group collapse",
      collposeClassBtn: "btn btn-secondary collapse",
    };
    this.appendStringhandleChange = this.appendStringhandleChange.bind(this);
    this.stringshandleChange = this.stringshandleChange.bind(this);
    this.myRef = React.createRef();
  }
  handleClick = (t) => {
    if (checkvalid(t.myRef.current)) {
      t.setState({
        collposeClass: "form-group collapse show",
      });
      t.setState({
        collposeClassBtn: "btn btn-secondary collapse show",
      });

      let array = t.state.strings.split("\n");
      let appendArray = [];
      for (let obj in array) {
        appendArray.push(t.state.appendString + array[obj]);
      }

      t.setState({
        outptString: appendArray.join("\n"),
      });
    }
    t.state.collposeClass = "form-group collapse show";
  };
  stringshandleChange(event) {
    //console.log(event.target.value);
    this.setState({ strings: event.target.value });
    //console.log(this.state.splitString);
  }
  appendStringhandleChange(event) {
    //console.log(event.target.value);
    this.setState({ appendString: event.target.value });
    //console.log(this.state.splitkey);
  }
  render() {
    return (
      <>
        <div className="tab-pane" id="beforeAppendString">
          <form className="needs-validation" novalidate ref={this.myRef}>
            <div className="form-group">
              <label>
                結合用文字列
                <Tooltip title="前に文字を追加したい文字列を記載してください" />
                <InputTextArea
                  placeholder="対象文字列"
                  value={this.state.strings}
                  onChange={this.stringshandleChange}
                />
                <InvalidFeedback />
              </label>
            </div>
            <div className="form-group">
              <label>
                追加文字
                <Tooltip title="文字列の前に追加する文字列を記載してください" />
                <InputTextBox
                  placeholder={"区切り文字"}
                  value={this.state.appendString}
                  onChange={this.appendStringhandleChange}
                />
                <InvalidFeedback />
              </label>
            </div>
            <div className={this.state.collposeClass}>
              <label>
                結果
                <OutputTextArea value={this.state.outptString} />
              </label>
            </div>
            <ExeButton
              onClick={() => {
                this.handleClick(this);
              }}
            />
            <CopyButton
              className={this.state.collposeClassBtn}
              onClick={() => {
                copyClick(this.myRef.current, this.state.outptString);
              }}
            />
          </form>
        </div>

        {/* <!-- 文字結合表示END --> */}
      </>
    );
  }
}
export default BeforeAppendtring;
