import React from "react";
import ReactDOM from "react-dom/client";
import Content from "./Content";
import { NavItemTooltipActive, NavItemTooltip } from "./NavItem.js";

class menu extends React.Component {
  render() {
    return (
      <>
        <ul className="nav nav-tabs pb-3">
          <NavItemTooltipActive
            target={"#nomal"}
            children={"一般"}
            title={"一般的な文字列操作"}
          />
          <NavItemTooltip
            target={"#program"}
            children={"プログラミング"}
            title={"プログラム使用想定文字列操作"}
          />
        </ul>
        <Content />
      </>
    );
  }
}

export default menu;
