/**
 * 文字数を判定し、真偽値を返します。
 * @param {Array(number)} args 文字列数です。
 * @return {bool} 判定真偽値
 */
export function isLength(...args) {
  if (2 > args.length) {
    return false;
  }
  for (let arg of args) {
    console.log(arg);
    if (arg == null || arg == 0 || typeof arg != "number") {
      return false;
    }
  }
  return true;
}

/**
 * ターゲット文字列にイン文字列を含むか判定します。
 * @param {string} target ターゲット文字列です。
 * @param {string} instr  イン文字列です。
 * @return {bool} 判定真偽値
 */
export function inString(target, instr) {
  if (target == null || typeof target != "string" || typeof instr != "string") {
    return false;
  }
  return target.indexOf(instr) != -1;
}

/**
 * 入力要素のチェックをします。
 * @param {object} form:「eeds-validation」クラスを含むformタグ
 */
export function checkvalid(form) {
  let bool = true;
  if (!form.checkValidity()) {
    form.classList.add("was-validated");
    bool = false;
  }
  return bool;
}

/**
 * データ出力エリアの出力文字列をもつオブジェクトを返します。
 * @constructor {string} inputString 入力文字列Id
 * @return {object} オブジェクト
 */
export class returnData {
  constructor(re, re2) {
    this.return = re;
    this.return2 = re2;
  }
}

export const copyClick = (form, copyString) => {
  if (checkvalid(form)) {
    //テキスト取得
    navigator.clipboard
      .writeText(copyString)
      .then(function () {
        let alert = document.getElementById("copied");
        alert.classList.add("show");
        alert.style.display = "block";
        setTimeout(() => {
          alert.classList.remove("show");
          alert.style.display = "";
        }, 3000);
      })
      .catch(function () {
        //console.error('クリップボードのコピーに失敗しました');
      });
  }
};
