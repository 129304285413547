import React from "react";
import { inString, checkvalid, copyClick } from "../commonfunction/function.js";
import { ExeButton, CopyButton } from "./elements/Button.js";
import { InvalidFeedback } from "./elements/InvalidFeedback.js";
import { Tooltip } from "./ModalTooltip.js";
import { InputTextBox, OutputTextArea } from "./elements/Input.js";

class SplitString extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      splitString: "",
      splitkey: "",
      outptString: "",
      collposeClass: "form-group collapse",
      collposeClassBtn: "btn btn-secondary collapse",
    };
    this.splitStringhandleChange = this.splitStringhandleChange.bind(this);
    this.splitkeyhandleChange = this.splitkeyhandleChange.bind(this);
    this.myRef = React.createRef();
  }
  handleClick = (t) => {
    if (
      checkvalid(t.myRef.current) &&
      inString(t.state.splitString, this.state.splitkey)
    ) {
      t.setState({
        collposeClass: "form-group collapse show",
      });
      t.setState({
        collposeClassBtn: "btn btn-secondary collapse show",
      });
      t.setState({
        outptString: t.state.splitString.split(this.state.splitkey).join("\n"),
      });
    }
    t.state.collposeClass = "form-group collapse show";
  };

  splitStringhandleChange(event) {
    this.setState({ splitString: event.target.value });
  }
  splitkeyhandleChange(event) {
    this.setState({ splitkey: event.target.value });
  }

  render() {
    return (
      <>
        <div className="tab-pane active" id="splitString">
          <form className="needs-validation" noValidate ref={this.myRef}>
            <div className="form-group">
              <label>
                対象文字列
                <Tooltip title="分割する文字列を記載してください" />
                <InputTextBox
                  placeholder={"対象文字列"}
                  value={this.state.splitString}
                  onChange={this.splitStringhandleChange}
                />
                <InvalidFeedback />
              </label>
            </div>
            <div className="form-group">
              <label>
                区切り文字
                <Tooltip title="分割する区切り文字を記載してください" />
                <InputTextBox
                  placeholder={"区切り文字"}
                  value={this.state.splitkey}
                  onChange={this.splitkeyhandleChange}
                />
                <InvalidFeedback />
              </label>
            </div>
            <div className={this.state.collposeClass}>
              <label>
                結果
                <OutputTextArea value={this.state.outptString} />
              </label>
            </div>
            <ExeButton
              onClick={() => {
                this.state = this.handleClick(this);
              }}
            />
            <CopyButton
              className={this.state.collposeClassBtn}
              onClick={() => {
                copyClick(this.myRef.current, this.state.outptString);
              }}
            />
          </form>
        </div>
        {/* <!-- 文字列分割表示END --> */}
      </>
    );
  }
}

export default SplitString;
