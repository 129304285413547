import React from "react";

/**
 * モーダルウィンドウを生成します。
 * @param {String} id:id文字列
 * @param {String} title:タイトル文字列
 * @param {Array} body:表示文字の配列
 */
export const BaseModal = (props) => {
  return (
    <>
      <div
        className="modal fade"
        id={`${props.id}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{`${props.title}`}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {props.body.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index + 1 !== props.body.length && <br />}
                </React.Fragment>
              ))}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
